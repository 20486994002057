import React from 'react'
import { Box, TextField, Button, Typography } from '@material-ui/core'
import Layout from '../components/layout'
import { makeStyles } from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles({
  textField: {
    marginTop: 20,
    marginBottom: 20,
    display: 'block',
  },
  box: {
    display: 'flex',
    marginTop: 10,
  },
})

export default function Contact() {
  const classes = useStyles()
  const [submit, setSubmit] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    setSubmit(true)
  }

  return (
    <Layout>
      {submit && <Alert severity='success'>Your request was submitted!</Alert>}

      <Typography align='center' variant='h4'>
        Contact Form
      </Typography>

      <Box className={classes.box} justifyContent='center'>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            className={classes.textField}
            label='Your Email'
          />
          <TextField
            variant='outlined'
            className={classes.textField}
            label='Your Name'
          />
          <TextField
            variant='outlined'
            className={classes.textField}
            label='Text'
            rows={4}
            multiline
          />

          <Button
            type='submit'
            color='primary'
            variant='contained'
            endIcon={<KeyboardArrowRightIcon />}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Layout>
  )
}
